body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App-header {
  font-size: calc(10px + 2vmin);
  color: white;
}
.apexcharts-menu.apexcharts-menu-open{
background-color: #000000c9;
}
.apexcharts-menu-item:hover {
  background: #00ccff7d!important
}