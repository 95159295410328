// change the theme
$theme-colors: (
  "primary": #00CCff,
  "warning": #ff9800 ,
  "info": #1CADB2,
  "danger": #DD1F48,
  "secondary": #23303e,
  "success": #1eb980,
  "gray": #475361,
  "dark":#19222c
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import './index.css'